import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import theme from "../../theme";
import MainDrawer from "../../components/Drawer/MainDrawer";
import {useDrawerToggle} from "../../hooks/useDrawerToggle";

function MainLayout() {
  
    const { drawerOpen } = useDrawerToggle();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainDrawer />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    padding: "16px",
                    height: "100vh",
                    backgroundColor: "#F6F5F4",
                    transition: "margin-left 0.3s",
                    marginLeft: drawerOpen ? "16%" : "72px",
                }}
            >
                <Outlet />
            </Box>
        </ThemeProvider>
    );
}

export default MainLayout;
