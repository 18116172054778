import { Box } from "@mui/material";
import React from "react";
// import ServiceIcon from "../../../assets/smlIcons/Dashboard Icons/Service.svg";
import ServiceIcon from "../../../../assets/smlIcons/Dashboard Icons/Service.svg";

function IconBox({ icon }: { icon: any }) {
  return (
    <Box
      sx={{
        backgroundColor: "#F6F5F4",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px",
      }}
    >
      <Box component="img" src={icon} sx={{ height: "1.5rem", width: "1.5rem" }} />
    </Box>
  );
}

export default IconBox;
