import { useLocation } from "react-router-dom";
import DtcPatternTable from "../../components/DtcPattern/DTCPatternTable";



const DtcPattern = () => {
    // Use the useLocation hook to get the current URL
    const location = useLocation();

    // Parse the faultCodes from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const faultCodesParam = queryParams.get("faultCodes");

    // Convert the faultCodes back to an array if it exists
    const faultCodes: string[] = faultCodesParam ? JSON.parse(faultCodesParam) : [];

    return (
        <DtcPatternTable name={faultCodes}/>
    );
};

export default DtcPattern;
