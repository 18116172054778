import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import theme from '../../theme';

interface ACUsage {
  [key: string]: number;
}

interface ACUsageTableProps {
  acUsage: ACUsage;
  liveACUsage?: Record<string, number>; // Adjust the type according to your actual data structure
}

const renderDot = (value: number) => (
  <Box
    sx={{
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: value === 1 ? 'green' : theme.palette.grey4.main,
      display: 'inline-block',
      marginLeft: 1
    }}
  />
);

const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours} hrs ${mins.toFixed(0)} mins`;
};

const calculatePercentage = (value: number, total: number) => {
  if (total === 0) return '0%';
  const percentage = (value / total) * 100;
  return `${percentage.toFixed(2)}%`;
};

const ACUsageTable: React.FC<ACUsageTableProps> = ({ acUsage, liveACUsage }) => {
  const totalUsage = Object.values(acUsage).reduce((sum, current) => sum + current, 0);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden'}}>
      <Typography variant="h6" component="div" sx={{ padding: 2 }}>
        AC Usage
      </Typography>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Mode/Status</TableCell>
            <TableCell>Selected Time Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(acUsage).map((key) => (
            <TableRow key={key}>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {key} {liveACUsage ? renderDot(liveACUsage[key]) : 'N/A'}
              </TableCell>
              <TableCell>{calculatePercentage(acUsage[key], totalUsage)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ACUsageTable;
