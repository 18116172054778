import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
  Button,
} from '@mui/material';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

interface DataTableProps {
  data: any[];
  uniqueId: string; // Add uniqueId as a prop
}

const DataTable: React.FC<DataTableProps> = ({ data, uniqueId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadCSV = () => {
    const columns = data.length > 0 ? Object.keys(data[0]).filter(col => col !== '__typename') : [];

    const csvData = data.map(row => {
      return columns.map(col => {
        const value = row[col];
        // Convert epoch timestamps to human-readable format
        if (typeof value === 'number' && col.toLowerCase().includes('time')) {
          return dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss');
        }
        return value !== null && value !== undefined ? value.toString() : 'NA';
      }).join(',');
    });

    const csvContent = [
      columns.join(','), // Header row
      ...csvData,        // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${uniqueId}_data.csv`); // Use uniqueId to name the file
  };

  // Get all unique keys from the first object in the data array and filter out "__typename"
  const columns = data.length > 0 ? Object.keys(data[0]).filter(col => col !== '__typename') : [];

  return (
    <Paper>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button variant="contained" color="primary" onClick={handleDownloadCSV}>
          Download CSV
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col}>{col}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col) => (
                  <TableCell key={col}>
                    {/* Render human-readable timestamps directly in the table */}
                    {typeof row[col] === 'number' && col.toLowerCase().includes('time')
                      ? dayjs.unix(row[col]).format('YYYY-MM-DD HH:mm:ss')
                      : row[col] !== null && row[col] !== undefined
                      ? row[col].toString()
                      : 'NA'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
};

export default DataTable;
