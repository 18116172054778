import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

interface MuiDatePickerProps {
  dateChange: (timestamp: number) => void;
  value: number;
  maxDate?: number;
  minDate?: number;
  disabled?: boolean;
}

const MuiDatePicker: React.FC<MuiDatePickerProps> = ({
  dateChange,
  value,
  maxDate,
  minDate,
  disabled,
}) => {
  // const convertedMaxDate = maxDate ? dayjs(maxDate) : undefined;
  // const convertedMinDate = minDate ? dayjs(minDate) : undefined;
  // console.log(maxDate, minDate, value, "muimui");
  return (
    <div>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={disabled}
            defaultValue={dayjs(new Date(value * 1000).toUTCString())}
            maxDateTime={maxDate ? dayjs(new Date(maxDate * 1000).toUTCString()) : undefined}
            minDateTime={minDate ? dayjs(new Date(minDate * 1000).toUTCString()) : undefined}
            //minDate={convertedMinDate}
            onAccept={(e: any) => {
              dateChange(Math.floor(new Date(e.$d).getTime() / 1000));
            }}
            closeOnSelect={false}
            format="DD/MM/YYYY hh:mm A"
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

export default MuiDatePicker;
