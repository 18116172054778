// import './Landing.css'
//import LanguageIcon from '@material-ui/icons/Language'
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import LoginForm from "./LoginForm";

import splash1 from "../../assets/png/sml-login.jpg";

function Login() {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Grid
      container
      sx={{
        padding: 0,
        margin: 0,
        height: "100vh",
        backgroundImage: `url(${splash1})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "0",
        backgroundPositionY: "0",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={3}
        sx={{
          position: "relative",
          backdropFilter: "blur(100px)",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // borderTopLeftRadius: "20px",
          // borderBottomLeftRadius: "20px",
          // overflow: "hidden",
        }}
      >
        <Box display="flex" justifyContent="center">
          <LoginForm />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
