import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from "@apollo/client";
import  createUploadLink from 'apollo-upload-client/createUploadLink.mjs'; // Import the upload link
import { setContext } from "@apollo/client/link/context";

// Create an upload link for file uploads
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL, // GraphQL endpoint
});

// Create an HTTP link for regular queries/mutations
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL, // Same endpoint
});

// Set up the authentication link to include the token in headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token"); // Get token from local storage
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "", // Include token if it exists
    },
  };
});

// Combine the links: first the auth link, then the upload link, and finally the HTTP link
const apolloClient = new ApolloClient({
  link: authLink.concat(uploadLink).concat(httpLink), // Chain the links
  cache: new InMemoryCache(),
});

// Export the Apollo client and ApolloProvider for use in your app
export { apolloClient, ApolloProvider };
