import React from "react";
import { Grid, Typography } from "@mui/material";

type StatusCardProps = {
    value: number | string;
    bgColor: string;
    title: string;
    fontColor: string;
    cardSize: number;
    onClick: () => void;
};

const StatusCard: React.FC<StatusCardProps> = ({ value, cardSize, bgColor, title, fontColor, onClick }) => {
    return (
        <Grid
            item
            xs={cardSize}
            sx={{
                backgroundColor: bgColor,
                borderRadius: "8.5px",
                cursor: "pointer",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '10px'
            }}
            onClick={onClick}
        >
            <Typography fontWeight="600" fontSize="1.5rem" style={{ color: fontColor }}>
                {value}
            </Typography>
            <Typography
                fontWeight="500"
                fontSize="0.8rem"
                style={{ color: fontColor, whiteSpace: "nowrap" }}
            >
                {title}
            </Typography>
        </Grid>
    );
};

export default StatusCard;
