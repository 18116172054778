import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { IUMPRData } from "../../types";

interface IUMPRTableProps {
  historicData: IUMPRData; // A single IUMPRData object
  liveData: IUMPRData | null; // Assuming live data is a single object
}

const IUMPRTable: React.FC<IUMPRTableProps> = ({ historicData, liveData }) => {
  const formatRatio = (completion: number, encountered: number) => {
    return `${completion} / ${encountered}`;
  };

  // Function to format the ratio for display in cells (rounded to two decimals)
  const formatRatioValue = (completion: number, encountered: number) => {
    if (encountered === 0 || isNaN(completion) || isNaN(encountered)) {
      return "N/A";
    }

    const ratio = completion / encountered;
    return ratio.toFixed(2);
  };

  // Function to style cells, showing red color for values below 0.1
  const getCellStyle = (completion: number, encountered: number) => {
    const ratio = encountered !== 0 ? completion / encountered : 0;
    return {
      color: ratio < 0.1 && ratio !== 0 ? "red" : "inherit",
    };
  };

  // Safely get live data values or default to 0
  const getLiveValue = (value: number | undefined | null) => value ?? 0;

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" component="div" sx={{ padding: 2 }}>
        IUMPR Data Table
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Metric</TableCell>
            <TableCell align="right">Lifetime Value (Counts / Conditions)</TableCell>
            <TableCell align="right">Lifetime Ratio</TableCell>
            <TableCell align="right">Selected Time Value (Counts / Conditions)</TableCell>
            <TableCell align="right">Selected Time Ratio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>OBD Monitoring Conditions Encountered</TableCell>
            <TableCell align="right">
              {historicData.obd_monitoring_conditions_encountered}
            </TableCell>
            <TableCell align="right">NA</TableCell>
            <TableCell align="right">
              {getLiveValue(liveData?.obd_monitoring_conditions_encountered)} {/* Use getLiveValue */}
            </TableCell>
            <TableCell align="right">NA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Ignition Cycle Counter</TableCell>
            <TableCell align="right">
              {historicData.ignition_cycle_counter}
            </TableCell>
            <TableCell align="right">NA</TableCell>
            <TableCell align="right">
              {getLiveValue(liveData?.ignition_cycle_counter)} {/* Use getLiveValue */}
            </TableCell>
            <TableCell align="right">NA</TableCell>
          </TableRow>

          {/* NMHC Catalyst Monitor */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              NMHC Catalyst Monitor Completion Counts / NMHC Catalyst Monitor Conditions Encountered
            </TableCell>
            <TableCell align="right">
              {formatRatio(historicData.nmhc_catalyst_monitor_completion_counts, historicData.nmhc_catalyst_monitor_conditions_encountered)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.nmhc_catalyst_monitor_completion_counts, historicData.nmhc_catalyst_monitor_conditions_encountered)}>
              {formatRatioValue(historicData.nmhc_catalyst_monitor_completion_counts, historicData.nmhc_catalyst_monitor_conditions_encountered)}
            </TableCell>
            <TableCell align="right">
              {formatRatio(getLiveValue(liveData?.nmhc_catalyst_monitor_completion_counts), getLiveValue(liveData?.nmhc_catalyst_monitor_conditions_encountered))} {/* Use getLiveValue */}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(getLiveValue(liveData?.nmhc_catalyst_monitor_completion_counts), getLiveValue(liveData?.nmhc_catalyst_monitor_conditions_encountered))}>
              {formatRatioValue(getLiveValue(liveData?.nmhc_catalyst_monitor_completion_counts), getLiveValue(liveData?.nmhc_catalyst_monitor_conditions_encountered))} {/* Use getLiveValue */}
            </TableCell>
          </TableRow>

          {/* NOX Catalyst Monitor */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              NOX Catalyst Monitor Completion Counts / NOX Catalyst Monitor Conditions Encountered
            </TableCell>
            <TableCell align="right">
              {formatRatio(historicData.nox_catalyst_monitor_completion_counts, historicData.nox_catalyst_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.nox_catalyst_monitor_completion_counts, historicData.nox_catalyst_monitor_conditions_encountered_counts)}>
              {formatRatioValue(historicData.nox_catalyst_monitor_completion_counts, historicData.nox_catalyst_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right">
              {formatRatio(getLiveValue(liveData?.nox_catalyst_monitor_completion_counts), getLiveValue(liveData?.nox_catalyst_monitor_conditions_encountered_counts))} {/* Use getLiveValue */}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(getLiveValue(liveData?.nox_catalyst_monitor_completion_counts), getLiveValue(liveData?.nox_catalyst_monitor_conditions_encountered_counts))}>
              {formatRatioValue(getLiveValue(liveData?.nox_catalyst_monitor_completion_counts), getLiveValue(liveData?.nox_catalyst_monitor_conditions_encountered_counts))} {/* Use getLiveValue */}
            </TableCell>
          </TableRow>

          {/* NOX Adsorber Monitor */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              NOX Adsorber Monitor Completion Counts / NOX Adsorber Monitor Conditions Encountered
            </TableCell>
            <TableCell align="right">
              {formatRatio(historicData.nox_adsorber_monitor_completion_counts, historicData.nox_adsorber_monitor_conditions_encountered)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.nox_adsorber_monitor_completion_counts, historicData.nox_adsorber_monitor_conditions_encountered)}>
              {formatRatioValue(historicData.nox_adsorber_monitor_completion_counts, historicData.nox_adsorber_monitor_conditions_encountered)}
            </TableCell>
            <TableCell align="right">
              {formatRatio(getLiveValue(liveData?.nox_adsorber_monitor_completion_counts), getLiveValue(liveData?.nox_adsorber_monitor_conditions_encountered))} {/* Use getLiveValue */}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(getLiveValue(liveData?.nox_adsorber_monitor_completion_counts), getLiveValue(liveData?.nox_adsorber_monitor_conditions_encountered))}>
              {formatRatioValue(getLiveValue(liveData?.nox_adsorber_monitor_completion_counts), getLiveValue(liveData?.nox_adsorber_monitor_conditions_encountered))} {/* Use getLiveValue */}
            </TableCell>
          </TableRow>

          {/* PM Filter Monitor */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              PM Filter Monitor Completion Counts / PM Filter Monitor Conditions Encountered
            </TableCell>
            <TableCell align="right">
              {formatRatio(historicData.pm_filter_monitor_completion_counts, historicData.pm_filter_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.pm_filter_monitor_completion_counts, historicData.pm_filter_monitor_conditions_encountered_counts)}>
              {formatRatioValue(historicData.pm_filter_monitor_completion_counts, historicData.pm_filter_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right">
              {formatRatio(getLiveValue(liveData?.pm_filter_monitor_completion_counts), getLiveValue(liveData?.pm_filter_monitor_conditions_encountered_counts))} {/* Use getLiveValue */}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(getLiveValue(liveData?.pm_filter_monitor_completion_counts), getLiveValue(liveData?.pm_filter_monitor_conditions_encountered_counts))}>
              {formatRatioValue(getLiveValue(liveData?.pm_filter_monitor_completion_counts), getLiveValue(liveData?.pm_filter_monitor_conditions_encountered_counts))} {/* Use getLiveValue */}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              Exhaust Gas Sensor Monitor Completion Counts / Exhaust Gas Sensor Monitor Conditions Encountered
            </TableCell>
            <TableCell align="right">
              {formatRatio(historicData.exhaust_gas_sensor_monitor_completion_counts, historicData.exhaust_gas_sensor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.exhaust_gas_sensor_monitor_completion_counts, historicData.exhaust_gas_sensor_conditions_encountered_counts)}>
              {formatRatioValue(historicData.exhaust_gas_sensor_monitor_completion_counts, historicData.exhaust_gas_sensor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right">
              {liveData ? formatRatio(liveData.exhaust_gas_sensor_monitor_completion_counts, liveData.exhaust_gas_sensor_conditions_encountered_counts) : "N/A"}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(liveData?.exhaust_gas_sensor_monitor_completion_counts || 0, liveData?.exhaust_gas_sensor_conditions_encountered_counts || 0)}>
              {liveData ? formatRatioValue(liveData.exhaust_gas_sensor_monitor_completion_counts, liveData.exhaust_gas_sensor_conditions_encountered_counts) : "N/A"}
            </TableCell>
          </TableRow>

          {/* Continue for EGR/VVT */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>EGR/VVT Monitor Completion Counts / EGR/VVT Conditions Encountered Counts</TableCell>
            <TableCell align="right">
              {formatRatio(historicData.egr_vvt_monitor_completion_counts, historicData.egr_vvt_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.egr_vvt_monitor_completion_counts, historicData.egr_vvt_conditions_encountered_counts)}>
              {formatRatioValue(historicData.egr_vvt_monitor_completion_counts, historicData.egr_vvt_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right">
              {liveData ? formatRatio(liveData.egr_vvt_monitor_completion_counts, liveData.egr_vvt_conditions_encountered_counts) : "N/A"}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(liveData?.egr_vvt_monitor_completion_counts || 0, liveData?.egr_vvt_conditions_encountered_counts || 0)}>
              {liveData ? formatRatioValue(liveData.egr_vvt_monitor_completion_counts, liveData.egr_vvt_conditions_encountered_counts) : "N/A"}
            </TableCell>
          </TableRow>

          {/* Continue for Boost Pressure */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Boost Pressure Monitor Completion Counts / Boost Pressure Conditions Encountered Counts</TableCell>
            <TableCell align="right">
              {formatRatio(historicData.boost_pressure_monitor_completion_counts, historicData.boost_pressure_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.boost_pressure_monitor_completion_counts, historicData.boost_pressure_monitor_conditions_encountered_counts)}>
              {formatRatioValue(historicData.boost_pressure_monitor_completion_counts, historicData.boost_pressure_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right">
              {liveData ? formatRatio(liveData.boost_pressure_monitor_completion_counts, liveData.boost_pressure_monitor_conditions_encountered_counts) : "N/A"}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(liveData?.boost_pressure_monitor_completion_counts || 0, liveData?.boost_pressure_monitor_conditions_encountered_counts || 0)}>
              {liveData ? formatRatioValue(liveData?.boost_pressure_monitor_completion_counts, liveData?.boost_pressure_monitor_conditions_encountered_counts) : "N/A"}
            </TableCell>
          </TableRow>

          {/* Continue for Fuel Monitor */}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Fuel Monitor Completion Counts / Fuel Monitor Conditions Encountered Counts</TableCell>
            <TableCell align="right">
              {formatRatio(historicData.fuel_monitor_completion_counts, historicData.fuel_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(historicData.fuel_monitor_completion_counts, historicData.fuel_monitor_conditions_encountered_counts)}>
              {formatRatioValue(historicData.fuel_monitor_completion_counts, historicData.fuel_monitor_conditions_encountered_counts)}
            </TableCell>
            <TableCell align="right">
              {liveData ? formatRatio(liveData.fuel_monitor_completion_counts, liveData.fuel_monitor_conditions_encountered_counts) : "N/A"}
            </TableCell>
            <TableCell align="right" sx={getCellStyle(liveData?.fuel_monitor_completion_counts || 0, liveData?.fuel_monitor_conditions_encountered_counts || 0)}>
              {liveData ? formatRatioValue(liveData.fuel_monitor_completion_counts, liveData.fuel_monitor_conditions_encountered_counts) : "N/A"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IUMPRTable;
