import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { gql, useQuery } from "@apollo/client";
import CustomSelect from "../../components/Miscellaneous/AutoComplete";
import StringSelect from "../../components/Settings/StringSelect";

// Define the GraphQL query
const GET_ALL_ALLOCATED_DTC = gql`
  query GetAllAllocatedDtc {
    getAllAllocatedDtc
  }
`;

interface DtcManagerProps {
  dtcType: string;
  localStorageKey: string;
}

const DtcManager: React.FC<DtcManagerProps> = ({
  dtcType,
  localStorageKey,
}) => {
  const [dtcList, setDtcList] = useState<string[]>([]);
  const [selectedDtc, setSelectedDtc] = useState<string>("");

  // Fetch data using the query
  const { loading, error, data } = useQuery(GET_ALL_ALLOCATED_DTC);

  useEffect(() => {
    const savedDtcList = localStorage.getItem(localStorageKey);
    if (savedDtcList) {
      setDtcList(JSON.parse(savedDtcList));
    }
  }, [localStorageKey]);

  const handleAddDtc = () => {
    if (selectedDtc && !dtcList.includes(selectedDtc) && dtcList.length < 10) {
      const newDtcList = [...dtcList, selectedDtc];
      setDtcList(newDtcList);
      localStorage.setItem(localStorageKey, JSON.stringify(newDtcList));
      setSelectedDtc(""); // Reset selected DTC after adding
    } else if (dtcList.length >= 10) {
      alert("You can only add up to 10 custom DTC codes.");
    } else if (dtcList.includes(selectedDtc)) {
      alert("This DTC code has already been added.");
    }
  };

  const handleDeleteDtc = (index: number) => {
    const newDtcList = dtcList.filter((_, i) => i !== index);
    setDtcList(newDtcList);
    localStorage.setItem(localStorageKey, JSON.stringify(newDtcList));
  };

  const handleSelectChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    setSelectedDtc(value || ""); // Update selected DTC
  };

  if (loading) return <CircularProgress />;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const dtcOptions = data?.getAllAllocatedDtc || [];

  return (
    <Box sx={{ padding: 2, marginBottom: 5 }}>
      <StringSelect
        options={dtcOptions} // Use fetched data as options
        handleSelectChange={handleSelectChange}
        placeholder="Select a DTC"
        value={selectedDtc}
      />
      <Button variant="contained" onClick={handleAddDtc} fullWidth sx={{marginTop:2}}>
        Add {dtcType} DTC Code
      </Button>
      <List sx={{ marginTop: 2 }}>
        {dtcList.map((dtc, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteDtc(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={dtc} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DtcManager;
