import React from "react";
import { Paper, Grid } from "@mui/material";
import CardTitle from "../HealthCountComponents/CardTItle";
import StatusCard from "../HealthCountComponents/StatusCard";
import DetailDialog from "../DataTableComponents/DetailDialog";
import Service from "../../../assets/smlIcons/Dashboard Icons/Service.svg"

interface CreepModeCardProps {
  creepModeCount: number;
}

const CreepModeCard: React.FC<CreepModeCardProps> = ({ creepModeCount }) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={3} sx={{ backgroundColor: "white", paddingBottom: "0.45rem" }}>
        <Grid container display={"flex"}>
          <Grid item xs={12} p={1.1}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
              <Grid item>
                <CardTitle Title="Creep Mode" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr
              style={{
                backgroundColor: "#F6F5F4",
                border: "none",
                height: "2px",
              }}
            />
          </Grid>
          <Grid container item xs={12} pt={0.8} padding={"16px"}>
            <StatusCard
              value={creepModeCount}
              bgColor="#C6EFCE"
              fontColor="#007B74"
              title="P1060"
              onClick={handleClickOpen} cardSize={12} />
          </Grid>
        </Grid>
        <DetailDialog open={dialogOpen} onClose={handleClose} name={"P1060"} queryType={"DTC"} />
      </Paper>
    </Grid>
  );
};

export default CreepModeCard;
