import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { Paper, Box, IconButton, Typography, Chip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface VehicleLampHistoryChartProps {
  historyData: { date: string; lamps: string[] }[];
}

const VehicleLampHistoryChart: React.FC<VehicleLampHistoryChartProps> = ({ historyData }) => {
  const lampCountMap = new Map<string, number>();
  historyData.forEach(history => {
    history.lamps.forEach(lamp => {
      if (lamp !== "NA") {
        lampCountMap.set(lamp, (lampCountMap.get(lamp) || 0) + 1);
      }
    });
  });

  const uniqueLamps = Array.from(lampCountMap.keys()).sort((a, b) => lampCountMap.get(a)! - lampCountMap.get(b)!);
  const dates = historyData.map(history => history.date);

  // Generate a distinct color for each DTC
  const generateColors = (numColors: number) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const hue = (i * 360) / numColors;
      colors.push(`hsl(${hue}, 70%, 50%)`);
    }
    return colors;
  };

  const dtcColors = generateColors(uniqueLamps.length);

  // Fixed color assignment for each DTC
  const dtcColorMap = uniqueLamps.reduce((map, lamp, index) => {
    map[lamp] = dtcColors[index];
    return map;
  }, {} as Record<string, string>);

  const [visibleLamps, setVisibleLamps] = useState<string[]>(uniqueLamps);

  // Ensure that at least one DTC is always visible
  const toggleDTCVisibility = (lamp: string) => {
    if (visibleLamps.includes(lamp)) {
      if (visibleLamps.length > 1) {
        setVisibleLamps(visibleLamps.filter((visibleDtc) => visibleDtc !== lamp));
      }
    } else {
      setVisibleLamps([...visibleLamps, lamp]);
    }
  };

  const zData = uniqueLamps.map(dtcCode =>
    dates.map(date => {
      const historyForDate = historyData.find(history => history.date === date);
      return historyForDate?.lamps.includes(dtcCode) ? uniqueLamps.indexOf(dtcCode) + 1 : 0;
    })
  );

  const colorscale: [number, string][] = uniqueLamps.map((lamp, index) => [
    (index + 1) / uniqueLamps.length,
    dtcColorMap[lamp]
  ]);

  colorscale.unshift([0, "rgba(255,255,255,0)"]); // Transparent for absent

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Typography variant="h6">Lamp History</Typography>
      <Box sx={{ padding: 2 }}>
        {/* Visibility toggles */}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, flexWrap: 'wrap' }}>
          {uniqueLamps.map((lamp) => (
            <Chip
              key={lamp}
              label={lamp}
              onClick={() => toggleDTCVisibility(lamp)}
              icon={visibleLamps.includes(lamp) ? <VisibilityIcon /> : <VisibilityOffIcon />}
              sx={{
                color: visibleLamps.includes(lamp) ? 'white' : 'black',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            />
          ))}
        </Box>

        <Plot
          data={[
            {
              z: zData.filter((_, index) => visibleLamps.includes(uniqueLamps[index])),
              x: dates,
              y: visibleLamps,
              type: "heatmap",
              colorscale: colorscale.filter((_, index) => visibleLamps.includes(uniqueLamps[index])),
              showscale: false,
              xgap: 5,
              ygap: 5,
            },
          ]}
          layout={{
            xaxis: {
              type: "category",
            },
            yaxis: {
              title: {
                text: "DTC Codes",
                standoff: 20,
              },
              tickmode: "array",
              automargin: true,
              type: "category",
              autorange: "reversed",
            },
            margin: { l: 50, r: 50, t: 50, b: 50 },
            height: 400,
          }}
          config={{ responsive: true }}
        />
      </Box>
    </Paper>
  );
};

export default VehicleLampHistoryChart;