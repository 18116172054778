import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { SoftwareVersions } from '../../types';

interface SoftwareVersionTableProps {
  softwareVersions: SoftwareVersions | null;
}

const SoftwareVersionTable: React.FC<SoftwareVersionTableProps> = ({ softwareVersions }) => {
  return (
    <Paper>
      <Typography variant="h6" component="div" sx={{ padding: 2 }}>
        Vehicle Softwares
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Software</TableCell>
            <TableCell>Current Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>EMS ECU Software Version</TableCell>
            <TableCell>{softwareVersions != null ? softwareVersions.emsEcuSoftwareVersion : "NA"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Meter Set Software Version</TableCell>
            <TableCell>{softwareVersions != null ? softwareVersions.meterSetSoftwareVersion : "NA"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>TCU Firmware Version</TableCell>
            <TableCell>{softwareVersions != null ? softwareVersions.firmwareVersion : 'NA'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SoftwareVersionTable;
