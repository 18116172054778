import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableSortLabel, // Import TableSortLabel
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';

interface ZSMDetail {
  zsmName: string | null;
  zsmEmployeeNo: string | null;
}

interface Employee {
  id: number;
  name: string;
  employeeno: string;
  email: string;
  mobile: string;
  createdat: string;
  updatedat: string;
  zsmDetails: ZSMDetail[];
}

interface ZSM {
  employeeno: string;
  name: string;
}

interface ServiceTableProps {
  employees: Employee[];
  isCCM: boolean;
}

// Query to get all ZSM details
const GET_ALL_ZSM_DETAILS = gql`
query GetAllZSMDetails {
  getAllZSMDetails {
    name
    employeeno
  }
}
`;

// Mutation to assign ZSM to CCM
const ASSIGN_ZSM_TO_CCM = gql`
  mutation AssignZSMToCCM($ccmEmployeeNo: String!, $zsmEmployeeNo: String!) {
    assignZSMToCCM(ccmEmployeeNo: $ccmEmployeeNo, zsmEmployeeNo: $zsmEmployeeNo) {
      status
      message
    }
  }
`;

const ServiceTable: React.FC<ServiceTableProps> = ({ employees, isCCM }) => {
  const [selectedCCM, setSelectedCCM] = useState<string | null>(null);
  const [zsmEmployeeNo, setZSMEmployeeNo] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [zsmData, setZSMData] = useState<ZSM[]>([]);

  // Sorting state
  const [sortField, setSortField] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { loading: zsmLoading, error: zsmError, refetch } = useQuery(GET_ALL_ZSM_DETAILS, {
    skip: true,
  });

  const [assignZSMToCCM, { loading: assigning }] = useMutation(ASSIGN_ZSM_TO_CCM, {
    refetchQueries: ['GetAllCCMDetails'],
  });

  // Handle opening the dialog and fetching ZSM data
  const handleAssignZSMClick = async (employeeNo: string) => {
    setSelectedCCM(employeeNo);
    setDialogOpen(true);

    try {
      const { data } = await refetch();
      setZSMData(data.getAllZSMDetails);
    } catch (error) {
      console.error("Error fetching ZSM data:", error);
    }
  };

  // Handle ZSM assignment
  const handleAssignZSM = async () => {
    if (selectedCCM && zsmEmployeeNo) {
      try {
        await assignZSMToCCM({ variables: { ccmEmployeeNo: selectedCCM, zsmEmployeeNo } });
        setDialogOpen(false);
        setSelectedCCM(null);
        setZSMEmployeeNo('');
      } catch (error) {
        console.error("Error assigning ZSM:", error);
      }
    }
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCCM(null);
    setZSMEmployeeNo('');
  };

  // Sort the employees array based on selected field and direction
  const sortedEmployees = [...employees].sort((a, b) => {
    let comparison = 0;

    if (sortField === 'name') {
      comparison = a.name.localeCompare(b.name);
    } else if (sortField === 'employeeno') {
      comparison = a.employeeno.localeCompare(b.employeeno);
    } else if (sortField === 'zsm') {
      const zsmA = a.zsmDetails.length > 0 ? a.zsmDetails[0].zsmName : '';
      const zsmB = b.zsmDetails.length > 0 ? b.zsmDetails[0].zsmName : '';
      comparison = zsmA!.localeCompare(zsmB!);
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  // Handle sorting
  const handleSort = (field: string) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="service table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'name'}
                  direction={sortField === 'name' ? sortDirection : 'asc'}
                  onClick={() => handleSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'employeeno'}
                  direction={sortField === 'employeeno' ? sortDirection : 'asc'}
                  onClick={() => handleSort('employeeno')}
                >
                  Employee No
                </TableSortLabel>
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              {isCCM && (
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'zsm'}
                    direction={sortField === 'zsm' ? sortDirection : 'asc'}
                    onClick={() => handleSort('zsm')}
                  >
                    ZSM Name
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEmployees.map((employee, index) => {
              const zsmAssigned = employee.zsmDetails && employee.zsmDetails.length > 0 && employee.zsmDetails[0].zsmName;
              return (
                <TableRow key={employee.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.employeeno}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>{employee.mobile}</TableCell>
                  {isCCM && (
                    <TableCell>
                      {zsmAssigned ? (
                        employee.zsmDetails[0].zsmName
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAssignZSMClick(employee.employeeno)}
                        >
                          Assign ZSM
                        </Button>
                      )}
                    </TableCell>
                  )}
                  <TableCell>{new Date(Number(employee.createdat) * 1000).toLocaleString()}</TableCell>
                  <TableCell>{new Date(Number(employee.updatedat) * 1000).toLocaleString()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for ZSM Assignment */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Assign ZSM</DialogTitle>
        <DialogContent>
          <Typography>Assigning ZSM to CCM Employee No: {selectedCCM}</Typography>

          {zsmLoading ? (
            <CircularProgress />
          ) : zsmError ? (
            <Typography>Error loading ZSM data</Typography>
          ) : (
            <FormControl fullWidth>
              <InputLabel>ZSM</InputLabel>
              <Select
                value={zsmEmployeeNo}
                onChange={(e) => setZSMEmployeeNo(e.target.value as string)}
                label="ZSM"
              >
                {zsmData.map((zsm: ZSM) => (
                  <MenuItem key={zsm.employeeno} value={zsm.employeeno}>
                    {zsm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAssignZSM}
            color="primary"
            disabled={assigning || !zsmEmployeeNo}
          >
            {assigning ? <CircularProgress size={24} /> : "Assign ZSM"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServiceTable;
