import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import { DPFParams } from "../../types";

interface DPFParamsTableProps {
  dpfHistory: DPFParams; // Expecting an array
  liveDpf: DPFParams | null; // Expecting an array or null
}

const DPFParamsTable: React.FC<DPFParamsTableProps> = ({
  liveDpf,
  dpfHistory,
}) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ width: "100%", mb: 1, p: 2 }}>
        <Typography variant="h6">DPF Parameters</Typography>
        <Grid container spacing={2}>
          {/* Table for live and historic data */}
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DPF Parameters</TableCell>
                  <TableCell align="right">Lifetime Value</TableCell> {/* Changed header */}
                  <TableCell align="right">Selected Time Value</TableCell> {/* Changed header */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Soot Mass</TableCell> {/* Bold font weight */}
                  <TableCell align="right">{dpfHistory.sootMass.toFixed(2)} gms</TableCell>
                  <TableCell align="right">{liveDpf?.sootMass} gms</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Regeneration Demands</TableCell> {/* Bold font weight */}
                  <TableCell align="right">{dpfHistory.regenerationDemands}</TableCell>
                  <TableCell align="right">{liveDpf?.regenerationDemands}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Successful Regenerations</TableCell> {/* Bold font weight */}
                  <TableCell align="right">{dpfHistory.successfulRegenerations}</TableCell>
                  <TableCell align="right">{liveDpf?.successfulRegenerations}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Distance Since Last Successful Regeneration</TableCell> {/* Bold font weight */}
                  <TableCell align="right">
                    {dpfHistory ? `${(dpfHistory.lastSuccessfulRegeneration/1000).toFixed(2)} Kms` : "N/A"}
                  </TableCell>
                  <TableCell align="right">{liveDpf ? `${(liveDpf.lastSuccessfulRegeneration/1000).toFixed(2)} Kms` : "N/A"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          {/* Graph for historic data */}
          {/* <Grid item xs={12}>
            <Typography variant="h6">Historic Data Graph</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dpfHistory}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip
                  formatter={(value: number, name: string, entry: any) => [
                    `${value}`,
                    name,
                  ]}
                  labelFormatter={(label) => `Date: ${label}`}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="sootMass"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  name="Soot Mass (gms)"
                />
                <Line
                  type="monotone"
                  dataKey="regenerationDemands"
                  stroke="#82ca9d"
                  name="Regeneration Demands"
                />
                <Line
                  type="monotone"
                  dataKey="successfulRegenerations"
                  stroke="#ffc658"
                  name="Successful Regenerations"
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid> */}
        </Grid>
      </Paper>
    </Box>
  );
};

export default DPFParamsTable;
