// context/DrawerContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Create a context
const DrawerContext = createContext({
  drawerOpen: false,
  toggleDrawer: () => {},
});

// Create a provider component
export const DrawerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  return (
    <DrawerContext.Provider value={{ drawerOpen, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

// Custom hook to use the drawer context
export const useDrawerToggle = () => {
  return useContext(DrawerContext);
};
