import React from "react";
import { Form, useNavigate, useSearchParams, redirect } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import LoadingButton from "../../components/Miscellaneous/LoadingButton";
import { gql, useLazyQuery } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { Box, Typography, } from "@mui/material";
import LandingPageLogo from "../../assets/png/sml-warroom.png";
import theme from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const LOGIN_QUERY = gql(`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        loginId
        username
      }
    }
  }
`);

function LoginForm() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams, _setSearchParams] = useSearchParams();
  const redirectURL = searchParams.get("redirect") || "/dashboard";

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  const [loginUser, _loginUserStatus] = useLazyQuery(LOGIN_QUERY);

  if (auth.user && auth.user.loginId) {
    redirect(redirectURL);
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const resp = await loginUser({
      variables: { username, password },
    });

    if (resp.error) {
      enqueueSnackbar("Invalid Username or Password!", { variant: "error" });
      setIsLoading(false);
      return;
    }

    if (resp.data && resp.data.login && resp.data.login.token) {
      const token = resp.data.login.token;

      // Validate the token format
      const tokenParts = token.split(".");
      if (tokenParts.length !== 3) {
        enqueueSnackbar("Invalid Token Format!", { variant: "error" });
        setIsLoading(false);
        return;
      }

      const login = auth.logIn(token);
      if (!login) {
        enqueueSnackbar("Invalid Token!", { variant: "error" });
        setIsLoading(false);
        return;
      }

      enqueueSnackbar("Success! Logged In", {
        variant: "success",
        autoHideDuration: 2000,
      });
      setIsLoading(false);
      setIsDone(true);
      setTimeout(() => {
        navigate(redirectURL);
      }, 1000);
      return;
    }
    enqueueSnackbar("Unable to Login!", { variant: "error" });
    setIsLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  return (
    <Grid
      container
      height={"100vh"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Grid
        item
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={LandingPageLogo}
              style={{
                width: "18rem",
                marginTop: "8rem",
                marginBottom: "0.6rem",
                borderRadius: "10px"
              }}
            />
          </Box>
          <Box>
            <Typography
              style={{
                color: "#888B8D",
                paddingTop: "1.5rem",
                fontSize: "1rem",
              }}
              fontWeight={"600"}
            >
              Vehicle Diagnostics Management
            </Typography>
          </Box>
        </Box>
        <Grid item xs={12} m={"1rem"} mt={"4rem"} mb={"2rem"}>
          <Grid container display={"flex"} justifyContent="space-between">
            <Grid item xs={5}>
              <Typography
                style={{ color: "#1C1B1B" }}
                fontWeight="800"
                fontSize="1.4rem"
              >
                Please log in
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <Form onSubmit={handleFormSubmit}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
              height={"18rem"}
              paddingX={"1.5rem"}
            >
              <Box width={"100%"}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="username"
                    type="text"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    placeholder="Username"
                    required
                    variant="outlined"
                    size="medium"
                    fullWidth
                    id="outlined-size-small"
                  />
                </FormControl>
              </Box>
              <Box width={"100%"}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Password"
                    required
                    variant="outlined"
                    size="medium"
                    fullWidth
                    id="outlined-adornment-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityIcon fontSize="small" />
                            ) : (
                              <VisibilityOffIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
              <Box width={"100%"}>
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                  inputProps={{
                    "aria-label": "I accept your terms and conditions",
                  }}
                />
                I accept your terms and conditions
              </Box>
              <Box width={"100%"}>
                <FormControl fullWidth>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    done={isDone}
                    disabled={isLoading || !termsAccepted}
                    fullWidth
                    style={{
                      backgroundColor: "black",
                      color: termsAccepted
                        ? "white"
                        : theme.palette.grey4.main,
                    }}
                  >
                    Sign In
                  </LoadingButton>
                </FormControl>
              </Box>
            </Box>
          </Form>
        </Box>
      </Grid>
      <Grid item width={"100%"} alignSelf={"flex-end"}>
        <Box sx={{ p: "10px", textAlign: "center" }}>
          <Box
            sx={{
              textDecoration: "none",
              color: theme.palette.black.main,
              fontSize: "1rem",
              fontWeight: "600",
            }}
          >
            Powered by SML Saarthi
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoginForm;
