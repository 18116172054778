import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { Paper, Box, IconButton, Typography, Chip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface VehicleDtcHistoryChartProps {
    historyData: { date: string; dtc_codes: string[] }[];
}

const VehicleDtcHistoryChart: React.FC<VehicleDtcHistoryChartProps> = ({ historyData }) => {
    const dtcCountMap = new Map<string, number>();
    historyData.forEach(history => {
        history.dtc_codes.forEach(dtc => {
            if (dtc !== "NA") {
                dtcCountMap.set(dtc, (dtcCountMap.get(dtc) || 0) + 1);
            }
        });
    });

    const uniqueDTCs = Array.from(dtcCountMap.keys()).sort((a, b) => dtcCountMap.get(a)! - dtcCountMap.get(b)!);
    const dates = historyData.map(history => history.date);

    // Generate a distinct color for each DTC
    const generateColors = (numColors: number) => {
        const colors = [];
        for (let i = 0; i < numColors; i++) {
            const hue = (i * 360) / numColors;
            colors.push(`hsl(${hue}, 70%, 50%)`);
        }
        return colors;
    };

    const dtcColors = generateColors(uniqueDTCs.length);

    // Fixed color assignment for each DTC
    const dtcColorMap = uniqueDTCs.reduce((map, dtc, index) => {
        map[dtc] = dtcColors[index];
        return map;
    }, {} as Record<string, string>);

    const [visibleDTCs, setVisibleDTCs] = useState<string[]>(uniqueDTCs);

    // Ensure that at least one DTC is always visible
    const toggleDTCVisibility = (dtc: string) => {
        if (visibleDTCs.includes(dtc)) {
            if (visibleDTCs.length > 1) {
                setVisibleDTCs(visibleDTCs.filter((visibleDtc) => visibleDtc !== dtc));
            }
        } else {
            setVisibleDTCs([...visibleDTCs, dtc]);
        }
    };

    const zData = uniqueDTCs.map(dtcCode =>
        dates.map(date => {
            const historyForDate = historyData.find(history => history.date === date);
            return historyForDate?.dtc_codes.includes(dtcCode) ? uniqueDTCs.indexOf(dtcCode) + 1 : 0;
        })
    );

    const colorscale: [number, string][] = uniqueDTCs.map((dtc, index) => [
        (index + 1) / uniqueDTCs.length,
        dtcColorMap[dtc]
    ]);

    colorscale.unshift([0, "rgba(255,255,255,0)"]); // Transparent for absent

    return (
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">DTC Code History</Typography>
            <Box sx={{ padding: 2 }}>
                {/* Visibility toggles */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2, flexWrap: 'wrap' }}>
                    {uniqueDTCs.map((dtc) => (
                        <Chip
                            key={dtc}
                            label={dtc}
                            onClick={() => toggleDTCVisibility(dtc)}
                            icon={visibleDTCs.includes(dtc) ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            sx={{
                                color: visibleDTCs.includes(dtc) ? 'white' : 'black',
                                margin: '0 5px',
                                cursor: 'pointer',
                            }}
                        />
                    ))}
                </Box>

                <Plot
                    data={[
                        {
                            z: zData.filter((_, index) => visibleDTCs.includes(uniqueDTCs[index])),
                            x: dates,
                            y: visibleDTCs,
                            type: "heatmap",
                            colorscale: colorscale.filter((_, index) => visibleDTCs.includes(uniqueDTCs[index])),
                            showscale: false,
                            xgap: 5,
                            ygap: 5,
                        },
                    ]}
                    layout={{
                        xaxis: {
                            type: "category",
                        },
                        yaxis: {
                            title: {
                                text: "DTC Codes",
                                standoff: 20,
                            },
                            tickmode: "array",
                            automargin: true,
                            type: "category",
                            autorange: "reversed",
                        },
                        margin: { l: 50, r: 50, t: 50, b: 50 },
                        height: 400,
                    }}
                    config={{ responsive: true }}
                />
            </Box>
        </Paper>
    );
};

export default VehicleDtcHistoryChart;
