import { Box, Chip, Typography } from "@mui/material";
import { VehicleFaultCode } from "../../types";
import React, { useState } from "react";
import DTCDetailCard from "../DtcDetail/DtcDetailCard";

interface DTCChipsProps {
    faultCodeData: VehicleFaultCode[];
}

const DTCChips: React.FC<DTCChipsProps> = ({ faultCodeData }) => {
    const [hoveredItem, setHoveredItem] = useState<{ faultCode: string; x: number; y: number } | null>(null);
    const [isHovered, setIsHovered] = useState(false); // Tracks if Chip or DTC Card is hovered
    let hoverTimeout: NodeJS.Timeout;

    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>, faultCode: string) => {
        const { clientX, clientY } = event;
        clearTimeout(hoverTimeout); // Clear any timeout if hovering
        setIsHovered(true); // Mark as hovered
        setHoveredItem({ faultCode, x: clientX, y: clientY });
    };

    const handleMouseLeave = () => {
        hoverTimeout = setTimeout(() => {
            setIsHovered(false); // Allow some delay before hiding
            setHoveredItem(null);
        }, 300); // Adjust the delay for smoother experience
    };

    const handleCardMouseEnter = () => {
        clearTimeout(hoverTimeout); // Clear the timeout if hovering the DTC Card
        setIsHovered(true);
    };

    const handleCardMouseLeave = () => {
        hoverTimeout = setTimeout(() => {
            setIsHovered(false); // Hide after delay
            setHoveredItem(null);
        }, 300);
    };

    const renderDTCcard = () => {
        if (!hoveredItem || hoveredItem.x === undefined || hoveredItem.y === undefined || !isHovered) {
            return null;
        }

        return (
            <div
                style={{
                    position: "fixed",
                    top: hoveredItem.y,
                    left: hoveredItem.x + 200, // Adjust this value for the distance from cursor
                    zIndex: 20,
                    backgroundColor: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                onMouseEnter={handleCardMouseEnter} // Keep card visible when hovered
                onMouseLeave={handleCardMouseLeave} // Hide after delay when mouse leaves
            >
                <DTCDetailCard faultCode={hoveredItem.faultCode} />
            </div>
        );
    };

    return (
        <>
            <Typography variant="h6">Live DTC Codes:</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", padding: "16px" }}>
                {faultCodeData.map((fault, index) => (
                    <Chip
                        key={index}
                        label={fault.dtcsae_meterset_updated}
                        onMouseEnter={(event) => handleMouseEnter(event, fault.dtcsae_meterset_updated)}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            margin: "4px",
                            backgroundColor: hoveredItem?.faultCode === fault.dtcsae_meterset_updated ? "#b2ebf2" : "#e0f7fa",
                            color: "#00695c",
                            border: "1px solid #00695c",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            fontWeight: "bold",
                            transform: hoveredItem?.faultCode === fault.dtcsae_meterset_updated ? "scale(1.1)" : "scale(1)",
                            transition: "transform 0.2s ease-in-out",
                        }}
                    />
                ))}
            </Box>
            {renderDTCcard()}
        </>
    );
};

export default DTCChips;
