import React from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface StringSelectProps {
  options: string[];
  handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>, value: string | null) => void;
  placeholder: string;
  value: string;
  disabled?: boolean;
}

const StringSelect: React.FC<StringSelectProps> = ({
  options,
  handleSelectChange,
  placeholder,
  value,
  disabled,
}) => {
  return (
    <FormControl fullWidth>
      <Autocomplete
        options={options}
        popupIcon={<ExpandMoreIcon />}
        onChange={(event, value) =>
          handleSelectChange(event as React.ChangeEvent<HTMLInputElement>, value as string)
        }
        disableClearable
        fullWidth
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default StringSelect;
