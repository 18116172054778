import { Box, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import DealerDataTable from "./DealerTable";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

const GET_ALL_DEALERS = gql`
query GetAllDealersDetails {
  getAllDealersDetails {
    a
    dcode
    sapCustomerId
    br
    dname
    dlrCategory
    dlrType
    address
    city
    contactNo
    stateName
    location
    phoneNo1
    serviceZone
    region
    cityClassification
    createDate
    latitude
    longitude
    salesNumber
    serviceNumber
    emailId
    isActive
    country
    serviceEmail
    salesEmail
    branchCode
    uniqueId
  }
}
`;

const DealerPage = () => {


    const { loading: dealerLoad, error: dealererr, data: dealerData } = useQuery(GET_ALL_DEALERS);

    const [searchQuery, setSearchQuery] = useState("");


        // Handle search input change
        const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(e.target.value);
      };
      // Filter ZSM data based on search query
      const filteredDealers = dealerData?.getAllDealersDetails.filter((dealer: { dname: string; }) =>
        dealer.dname.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [];
  


    return (
        <Box
            justifyContent="flex-start"
            sx={{ backgroundColor: "#F6F5F4", padding: 2 }}
        >
            <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                sx={{ padding: 1 }}
            >
                <Grid item xs={4}>
                    <Typography
                        sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}
                    >
                        Dealers
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Search Dealer"
                        variant="outlined"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </Grid>
            </Grid>

            <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            {dealerLoad ? (
                <CircularProgress />
              ) : dealererr ? (
                <Typography>Error loading data</Typography>
              ) : (
                <DealerDataTable dealers={filteredDealers} />
              )}
            </Box>
        </Box>
        )
}

export default DealerPage;