import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import React from "react";
import { createTheme, PaletteColorOptions, SimplePaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    grey1: SimplePaletteColorOptions;
    grey2: SimplePaletteColorOptions;
    grey3: SimplePaletteColorOptions;
    grey4: SimplePaletteColorOptions;
    black: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
    ctaBlue: SimplePaletteColorOptions;
    ctaBlueHover: SimplePaletteColorOptions;
    lightgreen: SimplePaletteColorOptions;
    lightgreenfont:SimplePaletteColorOptions;
    lightyellow:SimplePaletteColorOptions;
    lightyellowfont:SimplePaletteColorOptions;
    lightred:SimplePaletteColorOptions;
    lightredfont:SimplePaletteColorOptions;
    lightOrange:SimplePaletteColorOptions;
    lightOrangeFont: SimplePaletteColorOptions;
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    grey1?: SimplePaletteColorOptions;
    grey2?: SimplePaletteColorOptions;
    grey3?: SimplePaletteColorOptions;
    grey4?: SimplePaletteColorOptions;
    black?: SimplePaletteColorOptions;
    white?: SimplePaletteColorOptions;
    ctaBlue?: SimplePaletteColorOptions;
    ctaBlueHover?: SimplePaletteColorOptions;
    lightgreen?:SimplePaletteColorOptions;
    lightgreenfont?:SimplePaletteColorOptions;
    lightyellow?:SimplePaletteColorOptions;
    lightyellowfont?:SimplePaletteColorOptions;
    lightred?:SimplePaletteColorOptions;
    lightredfont?:SimplePaletteColorOptions;
    lightOrange?:SimplePaletteColorOptions;
    lightOrangeFont?: SimplePaletteColorOptions;
  }
}

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  typography: {
    fontFamily: [
      "Manrope",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#0056D6",
      light: "#0056D6",
      dark: "#0041A3",
    },
    secondary: {
      // main: "#888B8D",
      // light: "#aaaaaa",
      // dark: "#f0f0f0",
      main: "#061B2E",
      dark: "#192E45",
      light: "#273F5A",
    },
    error: { main: "#EB4E41" },
    warning: { main: "#F5B914" },
    success: { main: "#34B593" },
    grey1: { main: "#F6F5F4" },
    grey2: { main: "#A7A8A9" },
    grey3: { main: "#888B8D" },
    grey4: { main: "#53565A" },
    black: { main: "#1C1B1B" },
    white: { main: "#FFFFFF" },
    ctaBlue: { main: "#5094E5" },
    ctaBlueHover: { main: "#0C69BC" },
    lightgreen:{main:"#C6EFCE"},
    lightgreenfont:{main:"#007B74"},
    lightyellow:{main:"#FFEB9C"},
    lightyellowfont:{main:"#9C8C6F"},
    lightred:{main:"#FFC7CE"},
    lightredfont:{main:"#9C2D75"},
    lightOrange:{main:"#FFCC99"},
    lightOrangeFont:{main:"#3F3F76"}
  },
});

export default theme;

export const mapStylesLightMode = [];

// export const mapStylesLightMode = [
//   {
//     featureType: "poi",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     stylers: [
//       {
//         saturation: -70,
//       },
//       {
//         lightness: 37,
//       },
//       {
//         gamma: 1.15,
//       },
//     ],
//   },
//   {
//     elementType: "labels",
//     stylers: [
//       {
//         gamma: 0.26,
//       },
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     stylers: [
//       {
//         lightness: 0,
//       },
//       {
//         saturation: 0,
//       },
//       {
//         hue: "#ffffff",
//       },
//       {
//         gamma: 0,
//       },
//     ],
//   },
//   {
//     featureType: "road",
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road.arterial",
//     elementType: "geometry",
//     stylers: [
//       {
//         lightness: 20,
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry",
//     stylers: [
//       {
//         lightness: 50,
//       },
//       {
//         saturation: 0,
//       },
//       {
//         hue: "#ffffff",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.province",
//     stylers: [
//       {
//         visibility: "on",
//       },
//       {
//         lightness: -50,
//       },
//     ],
//   },
//   {
//     featureType: "administrative.province",
//     elementType: "labels.text.stroke",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.province",
//     elementType: "labels.text",
//     stylers: [
//       {
//         lightness: 20,
//       },
//     ],
//   },
// ];

export const mapStylesDarkMode = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#242f3e" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
