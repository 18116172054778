import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import AdblueIcon from "../../../assets/smlIcons/Dashboard Icons/Adblue.svg";
import CardTitle from "../HealthCountComponents/CardTItle";
import StatusCard from "../HealthCountComponents/StatusCard";
import DetailDialog from "../DataTableComponents/DetailDialog";
import { StatusOccurence } from "../../../types";

type AdblueLevelTableProps = {
    adblueData: StatusOccurence[];
};

const AdblueLevelTable: React.FC<AdblueLevelTableProps> = ({ adblueData }) => {

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<"LOW_ADBLUE" | "LOW_QUALITY_ADBLUE" | "P2BA9" | null>(null);

    const handleClickOpen = (status: string) => {
        console.log("Clicked status:", status); // Log the status to see its actual value
        
        let dialogStatus: "LOW_ADBLUE" | "LOW_QUALITY_ADBLUE" | "P2BA9" | null = null;
    
        const normalizedStatus = status.trim().toUpperCase(); // Normalize the status
        
        if (normalizedStatus === 'LOWADBLUELEVEL') {
            dialogStatus = 'LOW_ADBLUE';
        } else if (normalizedStatus === 'LOWADBLUEQUALITY') {
            dialogStatus = 'LOW_QUALITY_ADBLUE';
        } else if (normalizedStatus === 'P2BA9') {
            dialogStatus = 'P2BA9';
        }
    
        setSelectedStatus(dialogStatus);
        setDialogOpen(true);
    };
    
    const handleClose = () => {
        setDialogOpen(false);
        setSelectedStatus(null);
    };

    return (
        <Grid item xs={12}>
            <Paper elevation={3} sx={{ backgroundColor: "white", paddingBottom: "0.45rem" }}>
                <Grid container display={"flex"}>
                    <Grid item xs={12} p={1.1}>
                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            <Grid item>
                                <CardTitle Title="Adblue Levels" Icon={AdblueIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <hr style={{ backgroundColor: "#F6F5F4", border: "none", height: "2px" }} />
                    </Grid>
                    <Grid container item xs={12} columnGap={1} justifyContent={"space-between"} pt={0.8} padding={"16px"}>
                        {adblueData.map((data, index) => {
                            let title = '';
                            if (data.status === 'lowAdblueLevel') {
                                title = 'Low AdBlue Level';
                            } else if (data.status === 'lowAdblueQuality') {
                                title = 'Low AdBlue Quality';
                            } else if (data.status === 'P2BA9') {
                                title = 'P2BA9,P2BAB,P2BAC';
                            }
                            return (
                                <StatusCard
                                    key={index}
                                    value={data.count}
                                    bgColor="#8abfff" 
                                    fontColor="#09468f"
                                    title={title}
                                    onClick={() => handleClickOpen(data.status)}
                                    cardSize={3.8}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </Paper>
            {(selectedStatus === "LOW_ADBLUE" || selectedStatus === "LOW_QUALITY_ADBLUE") && (
                <DetailDialog 
                    open={dialogOpen} 
                    onClose={handleClose} 
                    name={selectedStatus} 
                    queryType="AdblueData"  // This is static for Adblue related dialogs
                />
            )}
            {selectedStatus === "P2BA9" && (
                <DetailDialog 
                    open={dialogOpen} 
                    onClose={handleClose} 
                    name={selectedStatus} 
                    queryType="DTC"  // Set queryType specifically for P2BA9 status
                />
            )}
        </Grid>
    );
};

export default AdblueLevelTable;
