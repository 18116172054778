import { Grid, Typography } from "@mui/material";

function Error404() {
  return (
    <Grid container height="100vh" width="100vw" justifyContent="center" alignContent="center">
      <Grid item>
        <Typography variant="h3">404 Not Found</Typography>
      </Grid>
    </Grid>
  );
}

export default Error404;