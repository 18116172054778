import { Typography, Box, Grid } from "@mui/material";
import theme from "../../../theme";

function CountCard({
  value,
  icon,
  title,
  color,
  onClick,
}: {
  value: number | string;
  icon: any;
  title: string;
  color: string;
  onClick: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: "#F6F5F4",
        borderRadius: 1,
        padding: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
            <Grid container columnGap={1}>
              <Grid
                item
                xs={3}
                sx={{
                  bgcolor: color,
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src={icon}
                  sx={{
                    height: "3rem",
                    //filter: "invert(100%)",
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <Grid container display="flex">
                  <Grid item xs={12}>
                    <Typography fontWeight="900" fontSize="1rem">
                      {value}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight="500" fontSize="0.8rem">
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
    </Box>
  );
}

export default CountCard;
