import { gql, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FaultCodeHistoryTable from "../../components/VehicleDiagnostics/VehicleFaultCodeCard";

const GET_DTC_DETAIL = gql`
  query GetSpecificFaultCodeDetails($dtc: String!) {
    getSpecificFaultCodeDetails(dtc: $dtc) {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type
      fault_type_hex
      dtcsae
      hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
    }
  }
`;

const GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY = gql`
  query GetVehicleLiveFaultCodeData($uniqueId: String!) {
    getVehicleLiveFaultCodeData(uniqueId: $uniqueId) {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type
      fault_type_hex
      dtcsae
      hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
    }
  }
`;

const DtcDetail = () => {
  const location = useLocation();

  // Parse the faultCodes from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const faultCode = queryParams.get("faultCode") || "";
  const uniqueId = queryParams.get("uniqueId") || "";

  // Call the useQuery hook for DTC details
  const { data: dtcData, loading: dtcLoading, error: dtcError } = useQuery(GET_DTC_DETAIL, {
    variables: { dtc: faultCode },
    skip: !faultCode, // Skip the query if no faultCode is provided
  });

  // Call the useQuery hook for vehicle live status and fault history
  const { data: vehicleData, loading: vehicleLoading, error: vehicleError } = useQuery(GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY, {
    variables: { uniqueId: uniqueId },
    skip: !uniqueId,
  });

  // Handle loading and error states
  if (dtcLoading || vehicleLoading) return <CircularProgress />;
  if (dtcError) return <Typography color="error">Error: {dtcError.message}</Typography>;
  if (vehicleError) return <Typography color="error">Error: {vehicleError.message}</Typography>;

  const faultCodeDetail = dtcData?.getSpecificFaultCodeDetails;
  const faultCodeHistory = vehicleData?.getVehicleLiveFaultCodeData;

  return (
    <>
      {(faultCodeDetail || faultCodeHistory) ? (
        <FaultCodeHistoryTable faultCodeHistory={faultCodeHistory} />
      ) : (
        <Typography>No details found for the provided fault code.</Typography>
      )}
    </>
  );
};

export default DtcDetail;
