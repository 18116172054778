import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableSortLabel,
} from "@mui/material";

// Define the interface for the dealer data
interface Dealer {
  a: string;
  dcode: number;
  dname: string;
  address: string;
  city: string;
  stateName: string;
  phoneNo1: string;
  emailId: string;
  latitude: number;
  longitude: number;
  uniqueId: string;
}

// Define the props interface
interface DealerDataTableProps {
  dealers: Dealer[];
}

// Sorting helper function
const getComparator = (order: "asc" | "desc", orderBy: keyof Dealer) => {
  return order === "desc"
    ? (a: Dealer, b: Dealer) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a: Dealer, b: Dealer) => (a[orderBy] < b[orderBy] ? -1 : 1);
};

const DealerDataTable: React.FC<DealerDataTableProps> = ({ dealers }) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Dealer>("dname");

  // Function to handle sorting requests
  const handleRequestSort = (property: keyof Dealer) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Function to open Google Maps with the dealer's location
  const handleAddressClick = (latitude: number, longitude: number) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  // Sort the dealers based on the selected column and order
  const sortedDealers = [...dealers].sort(getComparator(order, orderBy));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="h6">Dealer Code</Typography></TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "dname"}
                direction={orderBy === "dname" ? order : "asc"}
                onClick={() => handleRequestSort("dname")}
              >
                <Typography variant="h6">Dealer Name</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell><Typography variant="h6">Address</Typography></TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "city"}
                direction={orderBy === "city" ? order : "asc"}
                onClick={() => handleRequestSort("city")}
              >
                <Typography variant="h6">City</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "stateName"}
                direction={orderBy === "stateName" ? order : "asc"}
                onClick={() => handleRequestSort("stateName")}
              >
                <Typography variant="h6">State</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell><Typography variant="h6">Phone Number</Typography></TableCell>
            <TableCell><Typography variant="h6">Email</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedDealers.map((dealer) => (
            <TableRow key={dealer.uniqueId}>
              <TableCell>{dealer.dcode}</TableCell>
              <TableCell>{dealer.dname}</TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => handleAddressClick(dealer.latitude, dealer.longitude)}
                >
                  {dealer.address}
                </Typography>
              </TableCell>
              <TableCell>{dealer.city}</TableCell>
              <TableCell>{dealer.stateName}</TableCell>
              <TableCell>{dealer.phoneNo1}</TableCell>
              <TableCell>{dealer.emailId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DealerDataTable;
