import { createBrowserRouter } from "react-router-dom";
import auth from "../pages/auth";
import Home from "../pages/Home/Home";
import MainLayout from "../pages/MainLayout/MainLayout";
import VehiclePerformance from "../pages/VehiclePerformance/VehiclePerformance";
import VehicleDiagnostics from "../pages/VehicleDiagnostics/VehicleDiagnostics";
import Hierarchy from "../pages/Hierarchy/Hierarchy";
import Account from "../pages/Accounts/Account";
import DownloadData from "../pages/DownloadData/DownLoadData";
import DtcPattern from "../pages/DtcPatternPage/DtcPattern";
import DtcDetail from "../pages/DtcDetail/DtcDetail";
import DSMStrategyPage from "../pages/DSMStrategy/DSMStrategyPage";
import CustomDTC from "../pages/Settings/CustomDTC";
import Error from "../pages/Error/Error";
import Error404 from "../pages/Error/Error404";

const router = createBrowserRouter([
  { path: "/login", element: <auth.Login />, errorElement: <><Error /></> },
  {
    path: "/",
    element: <auth.Protected />,
    children: [
      { path: "logout", element: <auth.Logout /> },
      {
        path: "",
        element: <MainLayout />,
        children: [
          { path: "dashboard", element: <Home /> },
          { path: "vehicle-performance", element: <VehiclePerformance /> },
          { path: "vehicle-diagnostics", element: <VehicleDiagnostics /> },

          { path: "download-data", element: <DownloadData /> },
          {
            path: "settings",
            children: [
              // Default route for settings -> CustomDTC
              { index: true, element: <CustomDTC /> }, // Default route
              { path: "custom-dtc", element: <CustomDTC /> }, // Explicit route for custom-dtc
              { path: "hierarchy-data", element: <Hierarchy /> }, // Other routes within settings
              { path: "dsmstrategy", element: <DSMStrategyPage /> }
            ]
          },
          { path: "account", element: <Account /> },

        ],
      },
      { path: "dtcpattern", element: <DtcPattern /> },
      { path: "dtcdetail", element: <DtcDetail /> }
    ],
  },
  { path: "*", element: <Error404 /> },
]);

export default router;

