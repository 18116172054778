import React from "react";
import { Outlet, useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
function Protected() {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  React.useEffect(() => {
    if (!auth.isValidUser()) {
      let path = location.pathname;
      if (path.startsWith("/logout")) path = "/";
      const redirectParams = createSearchParams({ redirect: path }).toString();
      const redirectToPath = `/login?${redirectParams}`;
      navigate(redirectToPath);
    }
  });

  return <Outlet />;
}

export default Protected;
