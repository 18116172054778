import { Grid, Typography } from "@mui/material"
import DtcManager from "./DtcManger"

const CustomDTC = () => {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                        Custom Diesel DTC Codes
                    </Typography>
                    <DtcManager dtcType="Diesel" localStorageKey="customDieselDtcList" />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        Custom CNG DTC Codes
                    </Typography>
                    <DtcManager dtcType="CNG" localStorageKey="customCngDtcList" />
                </Grid>
            </Grid>
        </>
    )
}

export default CustomDTC;