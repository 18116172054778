import React from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LiveVehicle } from "../../types";

interface CustomSelectProps<T extends LiveVehicle> {
  options: T[];
  handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>, value: T | null) => void;
  label?: string;
  placeholder: string;
  onBlur?: () => void;
  error?: boolean;
  getOptionLabel: (option: T) => string;
  filterOptions?: (options: T[], state: { inputValue: string }) => T[];
  disabled?: boolean;
  value: T;
}

const CustomSelect = <T extends LiveVehicle,>(props: CustomSelectProps<T>) => {
  const {
    options,
    handleSelectChange,
    placeholder,
    onBlur,
    getOptionLabel,
    filterOptions,
    disabled,
    value,
  } = props;

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={options}
        popupIcon={<ExpandMoreIcon />}
        onBlur={onBlur}
        autoHighlight
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        onChange={(event, value) =>
          handleSelectChange(event as React.ChangeEvent<HTMLInputElement>, value as T)
        }
        disableClearable
        fullWidth
        value={value!}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              {option.vehicleNumber.toUpperCase() + '\n' + option.uniqueId.toUpperCase().replace("IT_", "") + '\n' + option.chassisNumber.toUpperCase()}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default CustomSelect;
