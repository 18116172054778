import { Box, Button, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ApolloQueryResult, gql, useMutation } from "@apollo/client";
import { VehicleFaultCode } from "../../types";

interface FaultCodeFormProps {
  onSubmit?: (faultCode: VehicleFaultCode) => void;
  initialValues?: Partial<VehicleFaultCode>;
  closeDialog: () => void;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

// Define the mutation for adding a new DTC detail
const ADD_DTC_DETAIL = gql`
  mutation AddDTCDetail($input: VehicleFaultCodeDescriptionInput!) {
    addDTCDetail(input: $input) {
      status
      message
    }
  }
`;

// Define the mutation for editing an existing DTC detail
const EDIT_DTC_DETAIL = gql`
  mutation EditDTCDetail($dtc_code: String!, $severity: String!) {
    editDTCDetail(dtc_code: $dtc_code, severity: $severity) {
      status
      message
    }
  }
`;

const FaultCodeForm: React.FC<FaultCodeFormProps> = ({ initialValues, closeDialog, refetch }) => {

  console.log(initialValues);

  const [formData, setFormData] = useState<VehicleFaultCode>({
    dfc_name: initialValues?.dfc_name || "",
    description: initialValues?.description || "",
    major_component: initialValues?.major_component || "",
    sub_component: initialValues?.sub_component || "",
    type_of_error: initialValues?.type_of_error || "",
    dtco_scantool: initialValues?.dtco_scantool || "",
    fault_type_hex: initialValues?.fault_type_hex || "",
    dtcsae_meterset_updated: initialValues?.dtcsae_meterset_updated || "",
    cause: initialValues?.cause || "",
    remedy: initialValues?.remedy || "",
    healing_conditions: initialValues?.healing_conditions || "",
    lamp_startegy_mil: initialValues?.lamp_startegy_mil || "",
    heal_trigger: initialValues?.heal_trigger || "",
    mil: initialValues?.mil || "",
    svs: initialValues?.svs || "",
    awl: initialValues?.awl || "",
    inducement: initialValues?.inducement || "",
    torque_limitation: initialValues?.torque_limitation || "",
    reaction: initialValues?.reaction || "",
    severity: initialValues?.severity || "",
    fuel_type: initialValues?.fuel_type || ""
  });

  const [addDTCDetail] = useMutation(ADD_DTC_DETAIL);
  const [editDTCDetail] = useMutation(EDIT_DTC_DETAIL);

  useEffect(() => {
    if (initialValues) {
      setFormData((prevData) => ({
        ...prevData,
        ...initialValues,
      }));
    }
  }, [initialValues]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (initialValues?.dtco_scantool) {
        // Editing existing DTC
        await editDTCDetail({ variables: { dtc_code: initialValues.dfc_name, severity: formData.severity } });
      } else {
        // Adding new DTC
        await addDTCDetail({ variables: { input: formData } });
      }
      await refetch();
      closeDialog(); // Close the dialog after submission
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const isEditing = Boolean(initialValues?.dtco_scantool);

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="DFC Name"
            name="dfc_name"
            value={formData.dfc_name}
            onChange={handleChange}
            
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
            
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Major Component"
            name="major_component"
            value={formData.major_component}
            onChange={handleChange}
            
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Sub Component"
            name="sub_component"
            value={formData.sub_component}
            onChange={handleChange}
            
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Type of Error"
            name="type_of_error"
            value={formData.type_of_error}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="DTCO (Scantool)"
            name="dtco_scantool"
            value={formData.dtco_scantool}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Fault Type (Hex)"
            name="fault_type_hex"
            value={formData.fault_type_hex}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="DTCSAE (Meterset updated)"
            name="dtcsae_meterset_updated"
            value={formData.dtcsae_meterset_updated}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Cause"
            name="cause"
            value={formData.cause}
            onChange={handleChange}
            multiline
            rows={2}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Remedy"
            name="remedy"
            value={formData.remedy}
            onChange={handleChange}
            multiline
            rows={2}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Healing Conditions"
            name="healing_conditions"
            value={formData.healing_conditions}
            onChange={handleChange}
            multiline
            rows={2}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Lamp Strategy (MIL)"
            name="lamp_startegy_mil"
            value={formData.lamp_startegy_mil}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Heal Trigger"
            name="heal_trigger"
            value={formData.heal_trigger}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="MIL"
            name="mil"
            value={formData.mil}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="SVS"
            name="svs"
            value={formData.svs}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="AWL"
            name="awl"
            value={formData.awl}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Inducement"
            name="inducement"
            value={formData.inducement}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Torque Limitation"
            name="torque_limitation"
            value={formData.torque_limitation}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Reaction"
            name="reaction"
            value={formData.reaction}
            onChange={handleChange}
            InputProps={{
              readOnly: isEditing, // Make field read-only if editing
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            label="Severity"
            name="severity"
            value={formData.severity} // This binds the selected value
            onChange={handleSelectChange}
            
          >
            <MenuItem value="Major">Major</MenuItem>
            <MenuItem value="Minor">Minor</MenuItem>
            <MenuItem value="Good">Good</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button type="submit" variant="contained" color="primary">
            {initialValues?.dtco_scantool ? "Update" : "Add"} DTC Code
          </Button>
        </Grid>

      </Grid>
    </Box>
  );
};

export default FaultCodeForm;
