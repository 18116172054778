import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import VehicleCard from "../../components/VehicleDiagnostics/VehicleInfoCard";
import FaultCodeHistoryTable from "../../components/VehicleDiagnostics/VehicleFaultCodeCard";
import { LiveVehicle } from "../../types";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import moment from "moment";
import DTCChips from "../../components/VehicleDiagnostics/DtcChips";
import VehicleDtcHistoryTable from "../../components/VehicleDiagnostics/VehicleDtcHistoryTable";
import VehicleDtcHistoryChart from "../../components/VehicleDiagnostics/DTCHistoryGraph";
import VehicleLampHistoryChart from "../../components/VehicleDiagnostics/LampHistoryGraph";

// Query to fetch vehicle live fault code data
const GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY = gql`
  query GetVehicleLiveFaultCodeData($uniqueId: String!) {
    getVehicleLiveFaultCodeData(uniqueId: $uniqueId) {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type
      fault_type_hex
      dtcsae
      hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
      fuel_type
      severity
    }
  }
`;

// Query to fetch all vehicle live statuses
const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const GET_VEHICLE_SALES_DATA = gql`
query GetSalesData($vin: [String!]) {
  getSalesData(vin: $vin) {
    chasisNo
    engineNo
    applicationType
    modelCategory
    productName
    vehicleType
    gvw
    passengerCapacity
    wheelBase
    engineType
    brakeType
    emissionStandard
    dealerCode
    dealerContactName
    dealerContactNumber
    fuelType
    adblueTankCapacity
    yearOfManufacturing
  }
}`;

const GET_VEHICLE_HISTORY_DTC_LAMPS = gql`
query GetHistoricFaultCodes($uniqueId: String!, $fromTs: Int!, $toTs: Int!) {
  getHistoricFaultCodes(uniqueId: $uniqueId, fromTs: $fromTs, toTs: $toTs) {
    date
    dtc_codes
    lamps
  }
}`

const VehicleDiagnostics = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(location.search);
  const uniqueId = queryParams.get("uniqueId");

  // Lazy queries
  const [getVehicleLiveStatusAndFaultHistory, { data: queryData, loading: queryLoading, error: queryError }] = useLazyQuery(GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY, {
    fetchPolicy: "network-only",
  });

  const [getSalesData, { data: salesData, loading: salesLoading, error: salesError }] = useLazyQuery(GET_VEHICLE_SALES_DATA);

  const [getDTCHistory, { data: historyData, loading: historyLoading, error: historyError }] = useLazyQuery(GET_VEHICLE_HISTORY_DTC_LAMPS);

  // State hooks
  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<LiveVehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<LiveVehicle | null>(null);
  const [fromTime, setFromTime] = useState<number>(Math.floor(new Date().setHours(0, 0, 0, 0) / 1000));
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, setToTime] = useState<number>(Math.floor(Date.now() / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [customerData, setCustomerData] = useState<any>(null);

  // Query for vehicle live status
  const [getAllVehicleLiveStatus, { loading: liveVehicleDataLoading, data: liveVehicleData, error: errorLiveVehicleData }] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });

  // Fetch all live vehicle statuses on component mount
  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [getAllVehicleLiveStatus]);

  // Handle option changes and update the time range
  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);

    switch (option) {
      case "HOUR":
        setFromTime(currentTimeInSeconds - 60 * 60); // Last 1 hour
        break;
      case "DAY":
        setFromTime(currentTimeInSeconds - 24 * 60 * 60); // Last 1 day
        break;
      case "WEEK":
        setFromTime(currentTimeInSeconds - 7 * 24 * 60 * 60); // Last 7 days
        break;
      case "MONTH":
        setFromTime(currentTimeInSeconds - 30 * 24 * 60 * 60); // Last 30 days
        break;
      default:
        break;
    }
  }, [option]);

  // Fetch vehicle fault history and sales data when the selected vehicle changes
  useEffect(() => {
    if (uniqueId && selectedVehicle) {
      getVehicleLiveStatusAndFaultHistory({ variables: { uniqueId, fromDate: fromTime } });

      // Fetch sales data for the selected vehicle
      if (selectedVehicle.chassisNumber) {
        getSalesData({ variables: { vin: [selectedVehicle.chassisNumber] } });
      }
    }
  }, [uniqueId, fromTime, selectedVehicle, getVehicleLiveStatusAndFaultHistory, getSalesData]);

  // Set customer data when sales data is fetched
  useEffect(() => {
    if (salesData && salesData.getSalesData) {
      setCustomerData(salesData.getSalesData[0]); // Assuming getSalesData returns an array
    }
  }, [salesData]);

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    setOption(e.target.value);
  };

  const handleSubmit = () => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
    } else if (
      (fromTime && fromTime > moment(moment.now()).unix()) ||
      toTime > moment(moment.now()).unix()
    ) {
      enqueueSnackbar("Future dates are not allowed");
    } else {
      setFromTime(fromTime);
      setToTime(toTime);
      if (selectedVehicle) {
        getVehicleLiveStatusAndFaultHistory({ variables: { uniqueId: selectedVehicle.uniqueId, fromDate: fromTime } });
        getDTCHistory({ variables: { uniqueId: selectedVehicle.uniqueId, fromTs: fromTime, toTs: toTime } })
        if (selectedVehicle.chassisNumber) {
          getSalesData({ variables: { vin: [selectedVehicle.chassisNumber] } });
        }
      }
    }
  };

  const handleSelectVehicle = (event: React.ChangeEvent<{}>, newValue: LiveVehicle) => {
    setSelectedVehicle(newValue);
  };

  const vehicleData = queryData?.getVehicleLiveFaultCodeData;
  const DTCHistory = historyData?.getHistoricFaultCodes;

  return (
    <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 2 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
            Vehicle Diagnostics Analytics
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TimePeriodSelector
            option={option}
            setFromTs={setFromTime}
            fromTs={fromTime}
            toTs={toTime}
            setToTs={setToTime}
            onOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
            handleSelectVehicle={handleSelectVehicle}
            vehicles={allVehicleLiveStatus}
            isDefaultSelected={isDefaultSelected}
            selectedVehicle={selectedVehicle}
            isDashBoard={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          {selectedVehicle &&
            customerData ? (
            <VehicleCard key={selectedVehicle.uniqueId} vehicle={selectedVehicle} customerData={customerData} />
          ) : salesLoading ? (
            <Box textAlign={"center"}><CircularProgress /></Box> // Show loading spinner for sales data
          ) : (
            <Box alignContent={"center"}><Typography textAlign={"center"}>No customer data available</Typography></Box>
          )}
        </Grid>

        <Grid item xs={12}>
          {selectedVehicle &&
            vehicleData ? (
            <DTCChips faultCodeData={vehicleData} />
          ) : queryLoading ? (
            <Box textAlign={"center"}><CircularProgress /></Box> // Show loading spinner for fault codes
          ) : (
            <Typography textAlign={"center"}>No fault code history available</Typography>
          )
          }
        </Grid>

        <Grid item xs={12}>
          {selectedVehicle &&
            DTCHistory ? (
            <Grid container spacing={2} xs={12}>
              <Grid item xs={6}>
                <VehicleDtcHistoryChart historyData={DTCHistory} />
              </Grid>
              <Grid item xs={6}>
                <VehicleLampHistoryChart historyData={DTCHistory} />
              </Grid>
            </Grid>
          ) : historyLoading ? (
            <Box textAlign={"center"}><CircularProgress /></Box> // Show loading spinner for charts
          ) : (
            <Typography textAlign={"center"}>No fault code history available</Typography>
          )
          }
        </Grid>

        <Grid item xs={12}>
          {selectedVehicle &&
            DTCHistory ? (
            <VehicleDtcHistoryTable historyData={DTCHistory} />
          ) : historyLoading ? (
            <Box textAlign={"center"}><CircularProgress /></Box> // Show loading spinner for DTC history
          ) : (
            <Typography textAlign={"center"}>No fault code history available</Typography>
          )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehicleDiagnostics;


