import { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { CircularProgress, Grid } from "@mui/material";
import { enqueueSnackbar } from "notistack";

function Logout() {
  const auth = useAuth();

  useEffect(() => {
    auth.logOut();
    enqueueSnackbar("Logged Out! Bye.", { variant: "success", preventDuplicate: true });
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <Grid item>
        <CircularProgress size="6rem" color="primary" />
      </Grid>
    </Grid>
  );
}

export default Logout;