import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { DTCHistory } from "../../types";

interface VehicleDtcHistoryTableProps {
    historyData: DTCHistory[];
}

const VehicleDtcHistoryTable: React.FC<VehicleDtcHistoryTableProps> = ({ historyData }) => {

    return (
        <TableContainer component={Paper} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">DTC HISTORY DATA</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>DTC Codes</TableCell>
                        <TableCell>Lamps</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {historyData.map((history, index) => (
                        <TableRow key={index}>
                            <TableCell>{history.date}</TableCell>
                            <TableCell>{history.dtc_codes.join(", ")}</TableCell>
                            <TableCell>{history.lamps.join(", ")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VehicleDtcHistoryTable;
