import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import Health from "../../../assets/smlIcons/Dashboard Icons/Health.svg";
import CardTitle from "./CardTItle";
import StatusCard from "./StatusCard"; // Import the new card component
import DetailDialog from "../DataTableComponents/DetailDialog";

type StatusOccurrence = {
    count: number;
    status: "GOOD" | "MAJOR_ISSUE" | "MINOR_ISSUE";
};

type HealthStatusProps = {
    healthIssues: StatusOccurrence[];
};

const HealthStatus: React.FC<HealthStatusProps> = ({ healthIssues }) => {
    // Extract counts based on status
    const countGoodHealthVehicles = healthIssues.find(issue => issue.status === "GOOD")?.count || 0;
    const countMajorIssueVehicles = healthIssues.find(issue => issue.status === "MAJOR_ISSUE")?.count || 0;
    const countMinorIssueVehicles = healthIssues.find(issue => issue.status === "MINOR_ISSUE")?.count || 0;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedName, setSelectedName] = useState<string | null>(null);

    const handleClickOpen = (name: string) => {
        setSelectedName(name);
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setSelectedName(null);
    };

    return (
        <Grid item xs={12}>
            <Paper elevation={3} sx={{ backgroundColor: "white", paddingBottom: "0.45rem" }}>
                <Grid container display={"flex"}>
                    <Grid item xs={12} p={1.1}>
                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            <Grid item>
                                <CardTitle Title="Health Status" Icon={Health} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <hr style={{ backgroundColor: "#F6F5F4", border: "none", height: "2px" }} />
                    </Grid>
                    <Grid container item xs={12} columnGap={1} justifyContent={"space-between"} pt={0.8} padding={"16px"}>
                        <StatusCard
                            value={countGoodHealthVehicles}
                            bgColor="#C6EFCE"
                            fontColor="#007B74"
                            title="Good Health"
                            onClick={() => handleClickOpen("GOOD")} cardSize={3.8} />
                        <StatusCard
                            value={countMinorIssueVehicles}
                            bgColor="#FFEB9C"
                            fontColor="#9C8C4F"
                            title="Minor Issue"
                            onClick={() => handleClickOpen("MINOR_ISSUE")} cardSize={3.8} />
                        <StatusCard
                            value={countMajorIssueVehicles}
                            bgColor="#FFC7CE"
                            fontColor="#9C2D75"
                            title="Major Issue"
                            onClick={() => handleClickOpen("MAJOR_ISSUE")} cardSize={3.8} />
                    </Grid>
                </Grid>
            </Paper>
            <DetailDialog open={dialogOpen} onClose={handleClose} name={selectedName} queryType={"HealthStatus"} />
        </Grid>
    );
};

export default HealthStatus;
