import React from "react";
import { useLocalStorage } from "./useLocalStorage";
import { jwtDecode, JwtPayload } from "jwt-decode";

type ReactNode =
  | React.ReactNode
  | React.ReactFragment
  | React.ReactPortal
  | boolean
  | null
  | undefined;

interface UserType {
  loginId: string;
  username: string;
  roles: string;
  exp: number;
  iat: number;
}

interface AuthContextType {
  token: string;
  user: UserType;
  logIn: (token: string, redirect?: string) => boolean;
  logOut: (redirect?: string) => boolean;
  isValidUser: () => boolean;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authToken, setAuthToken] = useLocalStorage("token", "");
  const [user, setUser] = useLocalStorage("user", "");

  const providedValues = React.useMemo(() => {
    try {
      const user: UserType = jwtDecode(authToken);
      return {
        token: authToken,
        user,
      };
    } catch (err) {
      return { token: "", user: {} as UserType };
    }
  }, [authToken]);

  const logIn = (token: string, redirect?: string) => {
    let decodedUser: UserType;
    try {
      decodedUser = jwtDecode<UserType>(token);
    } catch (err) {
      console.log(err);
      return false;
    }
    setAuthToken(token);
    setUser(JSON.stringify(decodedUser));

    if (redirect) {
      setTimeout(() => {
        window.location.replace(redirect);
      }, 500);
    }
    return true;
  };

  const logOut = (redirect?: string) => {
    setAuthToken("");
    localStorage.removeItem("token");
    setUser("");
    localStorage.removeItem("user");
    if (redirect) {
      setTimeout(() => {
        window.location.replace(redirect);
      }, 500);
    }
    return true;
  };

  const isValidUser = () => {
    if (providedValues.token && providedValues.user) {
      if (!providedValues.user.loginId || !providedValues.user.exp)
        return false;
      const expDate = new Date(providedValues.user.exp * 1000);
      const curDate = new Date(Date.now());

      if (expDate > curDate) return true;
    }
    return false;
  };

  const providedFunctions = { logIn, logOut, isValidUser };
  const authContext: AuthContextType = {
    ...providedValues,
    ...providedFunctions,
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(
    AuthContext as unknown as React.Context<AuthContextType>
  );
};
