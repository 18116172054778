import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  Box,
} from "@mui/material";
import { VehicleFaultCode } from "../../types";
import FaultCodeForm from "./FaultCodeForm";
import { ApolloQueryResult } from "@apollo/client";


interface DSMTableProps {
  faultCodeHistory: VehicleFaultCode[];
  onUpdateFaultCode: (updatedFaultCode: VehicleFaultCode) => void; // Prop to handle updating the fault code
  refetch: () => Promise<ApolloQueryResult<any>>;
}

const DSMTable: React.FC<DSMTableProps> = ({ faultCodeHistory, onUpdateFaultCode, refetch }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFaultCode, setSelectedFaultCode] = useState<VehicleFaultCode | null>(null);

  const handleEdit = (fault: any) => {
    // Remove __typename before setting the selected fault code
    const { __typename, ...cleanedFault } = fault;
    setSelectedFaultCode(cleanedFault); // Set the cleaned fault code object
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFaultCode(null);
  };

  const handleSubmitEdit = async (updatedFaultCode: VehicleFaultCode) => {
    onUpdateFaultCode(updatedFaultCode);
    await refetch(); // Refetch after updating the fault code
    handleCloseDialog(); // Close the dialog after submission
    
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "auto", marginTop: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Serial No</TableCell>
              <TableCell>DFC Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Major Component</TableCell>
              <TableCell>Sub Component</TableCell>
              <TableCell>Type of Error</TableCell>
              <TableCell>DTCO (Scantool)</TableCell>
              <TableCell>Fault Type (Hex)</TableCell>
              <TableCell>DTCSAE (Meterset updated)</TableCell>
              <TableCell>Cause</TableCell>
              <TableCell>Remedy</TableCell>
              <TableCell>Healing Conditions</TableCell>
              <TableCell>Lamp Strategy (MIL)</TableCell>
              <TableCell>Heal Trigger</TableCell>
              <TableCell>MIL</TableCell>
              <TableCell>SVS</TableCell>
              <TableCell>AWL</TableCell>
              <TableCell>Inducement</TableCell>
              <TableCell>Torque Limitation</TableCell>
              <TableCell>Reaction</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Fuel Type</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faultCodeHistory.map((fault, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{fault.dfc_name}</TableCell>
                <TableCell>{fault.description}</TableCell>
                <TableCell>{fault.major_component}</TableCell>
                <TableCell>{fault.sub_component}</TableCell>
                <TableCell>{fault.type_of_error}</TableCell>
                <TableCell>{fault.dtco_scantool}</TableCell>
                <TableCell>{fault.fault_type_hex}</TableCell>
                <TableCell>{fault.dtcsae_meterset_updated}</TableCell>
                <TableCell>{fault.cause}</TableCell>
                <TableCell>{fault.remedy}</TableCell>
                <TableCell>{fault.healing_conditions}</TableCell>
                <TableCell>{fault.lamp_startegy_mil}</TableCell>
                <TableCell>{fault.heal_trigger}</TableCell>
                <TableCell>{fault.mil}</TableCell>
                <TableCell>{fault.svs}</TableCell>
                <TableCell>{fault.awl}</TableCell>
                <TableCell>{fault.inducement}</TableCell>
                <TableCell>{fault.torque_limitation}</TableCell>
                <TableCell>{fault.reaction}</TableCell>
                <TableCell>{fault.severity}</TableCell>
                <TableCell>{fault.fuel_type}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(fault)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Dialog for editing the fault code */}
      {selectedFaultCode && (
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <DialogTitle >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {selectedFaultCode ? "Edit DTC Code" : "Add DTC Code"}
              <Button onClick={handleCloseDialog} color="secondary">Close</Button>
            </Box>
          </DialogTitle>
          <FaultCodeForm initialValues={selectedFaultCode || {}} closeDialog={handleCloseDialog} refetch={refetch}  />
        </Dialog>
      )}
    </>
  );
};

export default DSMTable;
