import { useEffect, useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useLocation } from "react-router-dom";
import theme from "../../theme";
import { useDrawerToggle } from "../../hooks/useDrawerToggle"; // Import the hook

interface DrawerMenuItemProps {
  item: {
    key: string;
    path?: string;
    text: string;
    icon: React.ElementType; // Should be a valid component type
    children: Array<{ key: string; path: string; text: string; icon: React.ElementType }>; // Define sub-item structure
  };
}

function DrawerMenuItem({ item }: DrawerMenuItemProps) {
  const hasSubmenu = item.children.length > 0;
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerOpen, toggleDrawer } = useDrawerToggle(); // Use the drawer toggle hook


  const listItemButtonStyle = (selected: boolean) => ({
    paddingBottom: 2,
    color: "#000",
    "&.Mui-selected": {
      color: `${theme.palette.error.main}`,
      backgroundColor: "#F6F5F4",
      "&:hover": {
        backgroundColor: "#F6F5F4",
      },
    },
    ...(drawerOpen || selected
      ? {}
      : {
        color: selected ? theme.palette.error.main : "#000",
      }),
  });

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [location.pathname, navigate]);


  const isCurrentPath = (path: string) => {
    return location.pathname.startsWith(path);
  };

  const verticalRedLine = {
    width: "5px",
    height: "100%",
    backgroundColor: theme.palette.error.main,
    borderRadius: "10px",
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path); // Only navigate if a path is defined
    }
  };
  
  const handleMenuClick = () => {
    if (hasSubmenu) {
      setSubmenuOpen((prev) => !prev); // Toggle submenu open state 
      if (!submenuOpen && item.children[0]?.path) {
        handleNavigation(item.children[0].path); // Navigate to the first child if submenu is closed
        toggleDrawer();
      }
    } else {
      handleNavigation(item.path); // Navigate when no submenu
    }
  };

  return (
    <>
      <ListItem disablePadding sx={listItemButtonStyle(isCurrentPath(item.path || ""))}>
        <ListItemButton
          selected={isCurrentPath(item.path || "")}
          onClick={handleMenuClick} // Use the new handler here
        >
          <ListItemIcon>
            <item.icon
              sx={{
                color: isCurrentPath(item.path || "") ? theme.palette.error.main : "#000",
              }}
            />
          </ListItemIcon>
          {drawerOpen && ( // Render text only if drawer is open
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                sx: {
                  fontSize: "1.25rem",
                  lineHeight: "1.5",
                  fontWeight: isCurrentPath(item.path || "") ? "500" : "400",
                },
              }}
            />
          )}
          {drawerOpen && hasSubmenu && (
            <IconButton edge="end">
              {submenuOpen ? (
                <KeyboardArrowDownIcon
                  sx={{ color: isCurrentPath(item.path || "") ? theme.palette.error.main : "#000" }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={{ color: isCurrentPath(item.path || "") ? theme.palette.error.main : "#000" }}
                />
              )}
            </IconButton>
          )}
          {/* Render the red line for selected main item */}
          {drawerOpen && !hasSubmenu && isCurrentPath(item.path || "") && (
            <Box
              sx={{
                ...verticalRedLine,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
  
      {drawerOpen && hasSubmenu && submenuOpen && (
        <div>
          {item.children.map((subItem) => (
            <ListItem key={subItem.key} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemButton
                selected={isCurrentPath(subItem.path)}
                onClick={() => handleNavigation(subItem.path)} // Navigate to submenu item
              >
                <ListItemIcon>
                  <subItem.icon
                    sx={{
                      color: isCurrentPath(subItem.path) ? theme.palette.error.main : "#000",
                    }}
                  />
                </ListItemIcon>
                {drawerOpen && ( // Render submenu text only if drawer is open
                  <ListItemText
                    primary={subItem.text}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "1rem",
                        lineHeight: "1.5",
                        fontWeight: isCurrentPath(subItem.path) ? "500" : "400",
                        color: isCurrentPath(subItem.path || "") ? theme.palette.error.main : "#000",
                      },
                    }}
                  />
                )}
                {/* Render the red line for selected sub-item */}
                {isCurrentPath(subItem.path) && (
                  <Box
                    sx={{
                      ...verticalRedLine,
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </div>
      )}
    </>
  );
}

export default DrawerMenuItem;
