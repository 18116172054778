import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Box, CircularProgress, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Input } from "@mui/material";
import { VehicleFaultCode } from "../../types";
import FaultCodeForm from "../../components/DSMStrategy/FaultCodeForm";
import DSMTable from "../../components/DSMStrategy/DSMTable";

// GraphQL queries and mutations
const GET_ALL_DSM_DETAIL = gql`
  query GetAllDsmDetail {
    getAllDsmDetail {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type
      fault_type_hex
      dtcsae
      hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
      severity
      fuel_type
    }
  }
`;

const GET_SAMPLE_DSM_SHEET = gql`
  query GetSampleDsmSheet {
    getSampleDsmSheet
  }
`;

const BULK_UPLOAD_DTC = gql`
  mutation BulkUploadDtc($file: Upload!) {
    bulkUploadDTC(file: $file) {
      status
      message
    }
  }
`;

const DSMStrategyPage = () => {
  const { data: dtcData, loading: dtcLoading, error: dtcError, refetch } = useQuery(GET_ALL_DSM_DETAIL);
  const { data: linkData, loading: linkLoading, error: linkError } = useQuery(GET_SAMPLE_DSM_SHEET);

  const [bulkUploadDtc] = useMutation(BULK_UPLOAD_DTC);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false); // State for upload dialog
  const [dsmDetailData, setDsmDetailData] = useState<VehicleFaultCode[]>([]);
  const [currentEdit, setCurrentEdit] = useState<Partial<VehicleFaultCode> | null>(null);
  const [file, setFile] = useState<File | null>(null);

  // Extract the download link when it's available
  const downloadLink = linkData?.getSampleDsmSheet;

  // Update dsmDetailData when dtcData is available
  useEffect(() => {
    if (dtcData) {
      setDsmDetailData(dtcData.getAllDsmDetail);
    }
  }, [dtcData]);

  // Function to open the add/edit form dialog
  const handleOpenDialog = (faultCode: Partial<VehicleFaultCode> | null = null) => {
    setCurrentEdit(faultCode);
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = async () => {
    setOpenDialog(false);
    setCurrentEdit(null); // Clear current edit on close
    await refetch();
  };

  const downloadSampleDsmSheet = () => {
    // if (downloadLink) {
    //   window.open(downloadLink, '_blank'); // Trigger the download
    // } else {
    //   console.error('Download link is not available.');
    // }
    window.open('/DSM_Sample.csv', '_blank');
  };

  // Handle file selection for CSV/Excel files
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    if (selectedFile && (selectedFile.type === 'application/vnd.ms-excel' || selectedFile.type === 'text/csv')) {
      setFile(selectedFile);
    } else {
      console.error('Please upload a valid CSV or Excel file.');
      setFile(null);
    }
  };

  // Upload the selected file to the server
  const handleUpload = async () => {
    if (file) {
      try {
        const response = await bulkUploadDtc({ variables: { file } });
        console.log(response.data.bulkUploadDTC.message);
        refetch(); // Refresh data after upload
        setUploadDialogOpen(false); // Close upload dialog after success
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.error('No file selected.');
    }
  };

  if (dtcLoading || linkLoading) return <CircularProgress />;
  if (dtcError) return <Typography color="error">Error: {dtcError.message}</Typography>;
  if (linkError) return <Typography color="error">Error: {linkError.message}</Typography>;

  return (
    <>
      <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
        <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 2 }}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
              Diagnostics Service Manual
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {/* Adjusting the layout with spacing between the buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
                Add DTC Code
              </Button>
              <Button variant="contained" color="primary" onClick={downloadSampleDsmSheet}>
                Sample DSM Sheet
              </Button>
              <Button variant="contained" color="primary" onClick={() => setUploadDialogOpen(true)}>
                Upload DTC Sheet
              </Button>
            </Box>
          </Grid>

          {/* DSM Table with Edit functionality */}
          {dsmDetailData ? (
            <DSMTable
              faultCodeHistory={dsmDetailData}
              onUpdateFaultCode={(updatedFaultCode: VehicleFaultCode) => {
                console.log('Updated Fault Code:', updatedFaultCode);
              }}
              refetch={refetch}
            />
          ) : (
            <Typography>No details found for the provided fault code.</Typography>
          )}
        </Grid>
      </Box>

      {/* Dialog for adding or editing a DTC code */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {currentEdit ? "Edit DTC Code" : "Add DTC Code"}
            <Button onClick={handleCloseDialog} color="secondary">Close</Button>
          </Box>
        </DialogTitle>
        <FaultCodeForm initialValues={currentEdit || {}} closeDialog={handleCloseDialog} refetch={refetch} />
      </Dialog>

      {/* Dialog for uploading the DTC sheet */}
      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Upload DTC Sheet</DialogTitle>
        <DialogContent>
          <Input
            type="file"
            inputProps={{ accept: ".csv, .xlsx" }}
            onChange={handleFileChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)} color="secondary">Cancel</Button>
          <Button onClick={handleUpload} color="primary" disabled={!file}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DSMStrategyPage;
