import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import styles from "./accounts.module.css";


const Account = () => {

    const navigate = useNavigate();
    return (
        <Box className={styles.header_cont}>
        <Box className={styles.header_title}>{("Account")}</Box>
        <Box className={styles.header_btn} onClick={() => navigate("/logout")}>
          {("Logout")}
        </Box>
      </Box>
    )
}

export default Account;