import React from "react";
import IconBox from "./IconBox";
import ServiceIcon from "../../../assets/smlIcons/Dashboard Icons/Service.svg";
import { Grid, Typography } from "@mui/material";

function CardTitle({ Title, SubTitle, Icon, }: { Title: string; SubTitle?: string; Icon?: any }) {
  return (
    <Grid container sx={{ display: "flex", alignItems: "center" }}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          {Icon && <Grid item>
            <IconBox icon={Icon} />
          </Grid>}
          <Grid item>
            <Typography sx={{ fontSize: "1.2rem", fontWeight: "600" }}>{Title}</Typography>
          </Grid>
          {SubTitle && (
            <Grid item ml={1}>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  color: "#888B8D",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {SubTitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CardTitle;
